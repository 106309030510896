<template>
  <div class="sonContent">
    <div class="SearchBar">
      <div style="display: flex">
        <span style="margin: 6px 10px 0 10px">工程编号/名称:</span>
        <el-input
          style="width: 200px"
          placeholder="请输入"
          size="small"
          v-model="searchBox.projectStr"
        ></el-input>
        <span style="margin: 6px 10px 0 10px">第三方施工队：</span>
        <el-input
          style="width: 200px"
          placeholder="请输入"
          size="small"
          v-model="searchBox.teamName"
        ></el-input>
        <span style="margin: 6px 10px 0 10px">现场管理员：</span>
        <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.siteManager"
        ></el-input>
        <div v-if="hanleBtn != 3 && hanleBtn != 5">
          <span style="margin: 6px 10px 0 10px">是否退料：</span>
          <el-select
            v-model="searchBox.timeType"
            style="width: 100px"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in chargeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <span style="margin: 6px 10px 0 10px">退料状态：</span>
          <el-select
            v-model="searchBox.timeType"
            style="width: 100px"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in tuiliaoList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div style="display: flex" v-if="hanleBtn == 3 || hanleBtn == 5">
          <el-select
            v-model="searchBox.timeType"
            style="width: 100px; margin: 0 10px"
            placeholder="请选择"
            size="small"
          >
            <el-option
              v-for="item in faliaoList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-date-picker
            size="small"
            v-model="time"
            type="datetimerange"
            @change="timeChange"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="选择开始日期时间"
            end-placeholder="选择结束日期时间"
          >
          </el-date-picker>
          <el-button
            type="primary"
            class="SearchBtn"
            size="small"
            @click="search"
            style="margin-left: 10px"
            >查询</el-button
          >
        </div>
      </div>
      <div
        style="display: flex; margin-top: 20px"
        v-if="hanleBtn != 3 && hanleBtn != 5"
      >
        <el-select
          v-model="searchBox.timeType"
          style="width: 100px; margin: 0 10px"
          placeholder="请选择"
          size="small"
        >
          <el-option
            v-for="item in dataList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-date-picker
          size="small"
          v-model="time"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="timeChange"
          start-placeholder="选择开始日期时间"
          end-placeholder="选择结束日期时间"
        >
        </el-date-picker>
        <el-button
          type="primary"
          class="SearchBtn"
          size="small"
          @click="search"
          style="margin-left: 10px"
          >查询</el-button
        >
      </div>
    </div>

    <!--数据表格-->
    <el-table
      ref="tableData"
      :data="tableData"
      style="width: 100%; margin: 15px 0"
      border
      height="calc(100vh - 394px)"
      :stripe="true"
    >
      <el-table-column type="index" label="序号" width="70" align="center">
      </el-table-column>
      <el-table-column
        prop="projectNumber"
        label="工程编号"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="projectName"
        label="工程名称"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="teamName"
        label="第三方施工队"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="siteManager"
        label="现场管理员"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="realname"
        label="提交人"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="提交时间"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="warehouseKeeper"
        label="审核人"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="warehouseKeeperTime"
        label="库房审核时间"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        v-if="hanleBtn == 5"
        prop="returnMode"
        label="退料方式"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        v-if="hanleBtn == 5"
        prop="returnTo"
        label="调拨工程"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="操作" align="left" show-overflow-tooltip>
        <template slot-scope="{ row }">
          <el-button
            v-if="hanleBtn == 1"
            @click="showDetil(row)"
            type="primary"
            size="small"
            >详情</el-button
          >
          <el-button
            v-if="hanleBtn == 1"
            @click="showDetil(row)"
            type="success"
            size="small"
            >完成退料</el-button
          >
          <el-button
            v-if="hanleBtn == 2"
            @click="showDetil(row)"
            type="primary"
            size="small"
            >详情</el-button
          >
          <el-button
            v-if="hanleBtn == 3"
            @click="returnApplyInfo(row)"
            type="primary"
            size="small"
            >详情</el-button
          >
          <el-button
            v-if="hanleBtn == 5"
            @click="returnApplyInfo(row)"
            type="primary"
            size="small"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pageBox">
      <el-pagination
        :current-page="searchBox.current"
        :background="true"
        :page-sizes="[30, 50, 100]"
        :page-size="searchBox.size"
        layout="total, prev, pager, next, sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="详情"
      :visible.sync="detildialogVisible"
      width="40%"
      :close-on-click-modal="false"
      @close="closeFrom('form')"
    >
      <div style="max-height: 600px; overflow: auto" v-if="detildialogVisible">
        <p class="detil-code">工程信息</p>
        <div style="line-height: 40px" >
          <div>工程编号：{{ project.projectNumber }}</div>
          <div>工程名称：{{ project.projectName }}</div>
          <div >工程类型：{{ project.projectType }}</div>
          <div >工程地点：{{ project.projectSite }}</div>
          <div>现场管理员：{{ project.siteManager }}</div>
        </div>

        <p class="detil-code" >申请信息</p>
        <div style="padding: 10px" >
          <div>第三方施工队：{{ project.teamName }}</div>
          <div style="display: flex;line-height: 50px">
            <div style="flex: 1" v-if="hanleBtn != 3">退料方式：{{ project.returnMode }}</div>
            <div style="flex: 1"  v-if="project.returnMode === '调拨退料'">调拨工程:{{ project.returnTo }}</div>
          </div>
          <div style="display: flex;">
            <div style="flex: 1">提交人：{{ project.realname }}</div>
            <div style="flex: 1">提交时间：{{ project.createTime }}</div>
          </div>
        </div>
        <p class="detil-code">现场管理审核</p>
        <div style="padding: 10px">
          <div style="display: flex; line-height: 50px">
            <div>
              <span style="margin-right: 15px"> {{hanleBtn == 3?'领料单签字照片：':'退料单签字照片：'}} </span>
              <div v-if="project.siteManagerUrl">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="project.siteManagerUrl.split(',')[0]"
                  :preview-src-list="project.siteManagerUrl.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{ project.siteManagerUrl.split(",").length }}张</span
                >
              </div>
            </div>
            
            <div style="flex: 1; display: flex" v-if="returnMode == '调拨退料'">
              <span style="margin-right: 15px">调拨单据照片：</span>
              <div v-if="project.allotUrl">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="project.allotUrl.split(',')[0]"
                  :preview-src-list="project.allotUrl.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{ project.allotUrl.split(",").length }}张</span
                >
              </div>
            </div>
          </div>
          <div style="display: flex; line-height: 50px">
            <div style="flex: 1">审核人：{{ project.siteManager }}</div>
            <div style="flex: 1">审核时间：{{ project.siteManagerTime }}</div>
          </div>
        </div>
        <p class="detil-code"> {{hanleBtn == 3?'审核信息':'库房退料审核信息'}} </p>  
        <div style="padding: 10px">
          <div style="display: flex; line-height: 50px" v-if="hanleBtn == 5">
            <div>
              <span style="margin-right: 15px">实际退料单据：</span>
              <div v-if="project.billUrl">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="project.billUrl.split(',')[0]"
                  :preview-src-list="project.billUrl.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{ project.billUrl.split(",").length }}张</span
                >
              </div>
            </div>
          </div>
          <div style="display: flex; line-height: 50px" v-if="hanleBtn == 3">
            <div>
              <span style="margin-right: 15px">实际领料单据：</span>
              <div v-if="project.billUrl">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="project.billUrl.split(',')[0]"
                  :preview-src-list="project.billUrl.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{ project.billUrl.split(",").length }}张</span
                >
              </div>
            </div>
            <div >
              <span style="margin-right: 15px">领料照片：</span>
              <div v-if="project.receivePhotoUrl">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="project.receivePhotoUrl.split(',')[0]"
                  :preview-src-list="project.receivePhotoUrl.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px"
                  >共{{ project.receivePhotoUrl.split(",").length }}张</span
                >
              </div>
            </div>
          </div>
          <div style="display: flex; line-height: 50px">
            <div style="flex: 1">附加描述:{{ project.remarks }}</div>
          </div>
          <div style="display: flex; line-height: 50px">
            <div style="flex: 1">审核人：{{ project.warehouseKeeper }}</div>
            <div style="flex: 1">
              审核时间：{{ project.warehouseKeeperTime }}
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detildialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <DetilCost :detilDialog="detilDialog" @closepop="closepop" />
  </div>
</template>

<script>
import {
  returnWarehouseConfirmRecord,
  returnApplyInfo,
  receiveWarehouseConfirmRecord,
  receiveApplyInfo,
} from "../../../RequestPort/cost/cost";
import DetilCost from "./detilCost";
export default {
  name: "dataTabel",
  components: { DetilCost },
  props: {
    hanleBtn: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      detilDialog: false,
      detildialogVisible: false,
      returnMode: null,
      project: {},
      searchBox: {
        current: 1,
        size: 100,
        timeType: 1,
      },
      time: [],
      total: null,
      tableData: [],
      dataList: [
        { label: "审核时间", value: 1 },
        { label: "确认时间", value: 2 },
        { label: "提交时间", value: 3 },
      ],
      faliaoList: [
        { label: "提交时间", value: 1 },
        { label: "库房审核时间", value: 2 },
      ],
      tuiliaoList: [
        { label: "未退料", value: 1 },
        { label: "退料中", value: 2 },
        { label: "退料完成", value: 3 },
      ],
      chargeList: [
        { label: "是", value: 1 },
        { label: "否", value: 2 },
      ],
    };
  },
  mounted() {
    // if(this.searchBox.time.length>0){
    //   this.searchBox.startTime = this.searchBox.time[0]
    //   this.searchBox.endTime = this.searchBox.time[1]
    // }

    this.loadList(this.searchBox);
  },
  methods: {
    timeChange(val) {
      if (val) {
        this.searchBox.startTime = val[0];
        this.searchBox.endTime = val[1];
      } else {
        this.searchBox.startTime = null;
        this.searchBox.endTime = null;
      }
    },
    returnApplyInfo(row) {
      let { hanleBtn } = this;
      console.log(hanleBtn)
      if (hanleBtn == 3) {
        receiveApplyInfo({ id: row.id }).then((res) => {

          this.project = res.data;
          this.returnMode = null;
          this.detildialogVisible = true;
        });
      } else if (hanleBtn == 5) {
        returnApplyInfo({ id: row.id }).then((res) => {
          this.project = res.data;
          this.returnMode = row.returnMode;
          this.detildialogVisible = true;
        });
      }
    },
    showDetil() {
      this.detilDialog = true;
    },
    closepop() {
      this.detilDialog = false;
    },
    loadList(obj) {
      let { hanleBtn } = this;
      if (hanleBtn == 3) {
        receiveWarehouseConfirmRecord(obj).then((res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        });
      } else if (hanleBtn == 5) {
        returnWarehouseConfirmRecord(obj).then((res) => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        });
      }
    },
    handleSizeChange(val) {
      this.searchBox.current = 1;
      this.searchBox.size = val;
      this.loadList(this.searchBox);
    },
    handleCurrentChange(val) {
      this.searchBox.current = val;
      this.loadList(this.searchBox);
    },
    search() {
      // if(this.searchBox.time.length>0){
      //   this.searchBox.startTime = this.searchBox.time[0]
      //   this.searchBox.endTime = this.searchBox.time[1]
      // }
      this.loadList(this.searchBox);
    },
  },
};
</script>

<style scoped>
.SearchBar {
  border: 1px solid #ecedf1;
  border-radius: 5px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.detil-code {
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
}
.detil-code:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007aff;
  top: 0;
  left: 0;
}
</style>